const layzrScript = document.getElementById("layzrJs");
if (layzrScript) {
  layzrScript.onload = function () {
    try {
      window.layzr = Layzr({threshold: 50});
      window.layzr.update().check().handlers(true);
    } catch (err) {
      console.error(err);
    }
  };
}

const paths = document.getElementsByClassName('lazy_path');

for (let i = 0; i < paths.length; i++) {
  paths[i].setAttribute(paths[i].dataset.type, paths[i].dataset.path);
}
